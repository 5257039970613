// **  Initial State
const initialState = {};

const caseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CASES":
      return {
        ...state,
        cases: action.payload,
      };
    case "GET_GOLDEN_CASES":
      return {
        ...state,
        goldenCases: action.payload,
      };
    case "GET_CASES_GENERAL_INFO":
      return {
        ...state,
        tags: action.data.cases_tags,
        types: action.data.cases_types,
      };
    case "REDIRECT_TO_CASES_LIST":
      return {
        ...state,
        redirectToCasesList: action.payload,
      }

    default:
      return state;
  }
};

export default caseReducer;
