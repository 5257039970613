// **  Initial State
const initialState = {
  data: [],
  form: {
    game_id: null,
  },
};

const gamesSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_GAMES_SETTINGS":
      return {
        ...state,
        data: payload,
      };
    case "GET_CURRENT_GAME_SETTINGS":
      return {
        ...state,
        form: {...payload},
      };
    case "CLEAR_CURRENT_GAME_SETTINGS":
      return {
        ...state,
        form: null,
      };
    default:
      return state;
  }
};

export default gamesSettingsReducer;