const initialState = {
  exchangeCoinsRate: {
    buy_market_rate: 0,
    sell_market_rate: 0,
    deposit_items_rate: 0,
    deposit_crypto_rate: 0,
    withdraw_crypto_rate: 0,
  },
  seo: {
    data: [],
    totalCount: 0,
    edit: {},
    redirectToListing: false,
  },
};

const siteSettingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EXCHANGE_COINS_RATES":     
      return {
        ...state,
        exchangeCoinsRate: payload,
      };
    case "GET_SEO_INFO":
      return {
        ...state,
        seo: payload,
      };
    case "GET_CURRENT_SEO_INFO":
      return {
        ...state,
        seo: {
          ...state.seo,
          edit: payload,
        },
      };
    case "CLEAR_CURRENT_SEO_INFO":
      return {
        ...state,
        seo: {
          ...state.seo,
          edit: {},
        },
      };
    case "SEO_REDIRECT_TO_LISTING":
      return {
        ...state,
        seo: {
          ...state.seo,
          redirectToListing: payload,
        },
      };
    default:
      return state;
  }
};

export default siteSettingsReducer;