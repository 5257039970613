// **  Initial State
const initialState = {
  giftCards: [],
  currentGiftCard: null,
  giftCardCodes: [],
  giftCardCodesTotalCount: 0,
};

const giftReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_GIFT_CARDS":
      return {
        ...state,
        giftCards: payload,
      };
    case "GET_CURRENT_GIFT_CARD":
      return {
        ...state,
        currentGiftCard: payload,
      };
    case "CLEAR_CURRENT_GIFT_CARD":
      return {
        ...state,
        currentGiftCard: null,
      };
    case "GET_GIFT_CARD_CODES":
      return {
        ...state,
        giftCardCodes: payload,
      };
    case "GET_GIFT_CARD_CODES_TOTAL_COUNT":
      return {
        ...state,
        giftCardCodesTotalCount: payload,
      };
    default:
      return state;
  }
};

export default giftReducer;
