// **  Initial State
const initialState = {};

const evetsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_EVENTS":
      return {
        ...state,
        events: payload,
      };
    case "GET_EVENT_IMAGES":
      return {
        ...state,
        eventImages: payload,
      }
    case "GET_LEADERBOARD":
      return {
        ...state,
        leaderBoard: payload,
      }
    case "GET_LEADERBOARD_PRIZES":
      return {
        ...state,
        leaderBoardPrizes: payload,
      }
    default:
      return state;
  }
};

export default evetsReducer;