// **  Initial State
const initialState = { counts: {} };

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        users: action.data,
      };

    case "GET_USER":
    case "UPDATE_USER":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  ...action.data,
                },
              }
            : {
                ...(state.userInfo ? state.userInfo : {}),
                [action.params.user_id]: { ...action.data },
              },
        // userInfo:
        //   !state.userInfo || state.userInfo[action.data.id]
        //     ? { [action.data.id]: action.data }
        //     : {
        //         ...state.userInfo,
        //         [action.data.id]: {
        //           ...state.userInfo[action.data.id],
        //           ...action.data,
        //         },
        //       },
      };

    case "GET_ADMIN_USERS":
      return {
        ...state,
        admins: action.data,
        adminsObj: action.callBackData,
      };

    case "GET_MERCHANT":
      return {
        ...state,
        merchant: action.data,
      };

    case "GET_OFFERS":
      return {
        ...state,
        offers: action.data,
      };

    case "UPDATE_OFFERS":
      return {
        ...state,
        offers: action.payload,
      };
    case "UPDATE_TRANSACTIONS":
      return {
        ...state,
        transactions: action.payload,
      };

    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.data,
      };

    case "GET_TICKET":
      return {
        ...state,
        ticket: action.data,
      };

    case "GET_BETS":
      return {
        ...state,
        bets: action.data,
      };
    case "GET_STATS":
      return {
        ...state,
        stats: action.data,
      };

    case "GET_PROMO":
      return { ...state, promo: action.data[0] };

    case "ADD_PROMO":
      return { ...state, promo: [action.data, ...state.promo] };

    case "GET_PROMO_USERS":
      return { ...state, promoUsers: action.data };

    case "GET_USERS_ACTVIVITY":
      return { ...state, usersActivityCount: action.optData.total_count, usersActivity: action.data };

    case "GET_MONITORS":
      return {
        ...state,
        monitors: action.data,
      };

    case "GET_USER_ACTIVITY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userActivity: action.data,
                },
              }
            : { [action.params.user_id]: { userActivity: action.data } },
      };

    case "GET_USER_TICKETS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userTickets: action.data,
                },
              }
            : { [action.params.user_id]: { userTickets: action.data } },
      };

    case "GET_USER_AFFILIATE_HISTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.userId]
            ? {
                ...state.userInfo,
                [action.params.userId]: {
                  ...state.userInfo[action.params.userId],
                  affiliateHistory: action.data,
                },
              }
            : { [action.params.userId]: { affiliateHistory: action.data } },
      };

    case "GET_USER_SETTINGS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userSettings: action.data,
                },
              }
            : { [action.params.user_id]: { userSettings: action.data } },
      };

    case "GET_USER_INVENTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userInventory: action.data,
                },
              }
            : { [action.params.user_id]: { userInventory: action.data } },
      };

    case "GET_USER_INVENTORY_HISTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.userId]
            ? {
                ...state.userInfo,
                [action.params.userId]: {
                  ...state.userInfo[action.params.userId],
                  userInventoryHistory: action.data,
                },
              }
            : { [action.params.userId]: { userInventoryHistory: action.data } },
      };

    case "GET_USER_BALLANCE_HISTORY":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.userId]
            ? {
                ...state.userInfo,
                [action.params.userId]: {
                  ...state.userInfo[action.params.userId],
                  userBalanceHistory: action.data,
                },
              }
            : { [action.params.userId]: { userBalanceHistory: action.data } },
      };

    case "GET_USER_OFFERS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
                ...state.userInfo,
                [action.id]: {
                  ...state.userInfo[action.id],
                  userOffers: action.data,
                },
              }
            : { [action.id]: { userOffers: action.data } },
      };

    case "GET_USER_GIFT_CODES":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
                ...state.userInfo,
                [action.id]: {
                  ...state.userInfo[action.id],
                  userGiftCodes: action.data,
                },
              }
            : { [action.id]: { userGiftCodes: action.data } },
      };

    case "GET_USER_TRANSACTIONS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
                ...state.userInfo,
                [action.id]: {
                  ...state.userInfo[action.id],
                  userTransactions: action.data,
                },
              }
            : { [action.id]: { userTransactions: action.data } },
      };

    case "GET_USER_BALLANCE":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userBalance: action.data,
                },
              }
            : { [action.params.user_id]: { userBalance: action.data } },
      };

    case "UPDATE_USER_BALANCE":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.data.user_id]
            ? {
                ...state.userInfo,
                [action.data.user_id]: {
                  ...state.userInfo[action.data.user_id],
                  userBalance: { wallets: action.data },
                },
              }
            : {
                [action.data.user_id]: {
                  userBalance: { wallets: action.data },
                },
              },
      };

    case "GET_USER_AFF_BONUS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userAffiliateBonus: action.data,
                },
              }
            : { [action.params.user_id]: { userAffiliateBonus: action.data } },
      };
    case "GET_USER_PROMOCODE":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.params.user_id]
            ? {
                ...state.userInfo,
                [action.params.user_id]: {
                  ...state.userInfo[action.params.user_id],
                  userPromocode: action.data,
                },
              }
            : { [action.params.user_id]: { userPromocode: action.data } },
      };

    case "GET_USER_BETS":
      return {
        ...state,
        userInfo:
          state.userInfo && state.userInfo[action.id]
            ? {
                ...state.userInfo,
                [action.id]: {
                  ...state.userInfo[action.id],
                  userBets: action.data,
                },
              }
            : { [action.id]: { userBets: action.data } },
      };

    case "GET_BOT_SETTINGS":
      return {
        ...state,
        botSetings: action.data.map((item) => {
          return {
            ...item.bot,
            gamesSettings: [...item.game_settings],
          }
        }),
        botSettingsCount: action.optData.total_count,
      };

    case "GET_MARKET_TRANSACTIONS":
      return {
        ...state,
        marketTransactions: action.data,
      };

    case "UPDATE_BOT_SETTINGS":
    case "DELETE_BOT_SETTINGS":
      return {
        ...state,
        botSetings: action.callBackData
          ? action.callBackData
          : state.botSetings,
      };

    case "COUNT":
      return {
        ...state,
        counts: { ...state.counts, [action.name]: action.count },
      };

    case "GET_USERS_COUNT":
      return {
        ...state,
        userCounts: {
          ...state.userCounts,
          [action.id]: action.data.count_users,
        },
      };

    default:
      return state;
  }
};

export default dataReducer;
