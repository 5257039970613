// ** Initial user ability
export const initialAbility = [
  {
    action: "read",
    subject: "all",
  },
  {
    action: "manage",
    subject: "manager",
  },
];

export const _ = undefined;
