import { Ability } from '@casl/ability';
import { initialAbility } from './initialAbility';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'));
let renderAbility = userData && userData.permissions ?  userData.permissions : initialAbility
// const existingAbility = userData
// 	? { action: userData.roles === 'administrator' ? 'administrator' : '', subject: userData.roles }
// 	: null;
// export default new Ability(existingAbility || initialAbility);
export default new Ability(renderAbility);
