// **  Initial State
const initialState = { 
  userApiKey: "",
 };

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_API_KEY":
      console.log(action)
      return {
        ...state,
        userApiKey: action.data.api_key,
      }

    default:
      return state;
  }
};

export default dataReducer;
