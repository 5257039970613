// **  Initial State
const initialState = {};

const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRANSLATIONS_GROUP":
      return {
        ...state,
        translationGroups: action.data,
        totalTranslates: action.optData.total_count
      };
    case "GET_TRANSLATIONS":
      return {
        ...state,
        translations: action.data,
      };

    case "GET_LANGUAGES":
      return {
        ...state,
        languagesList:action.languagesList
      }
    case "CLEAR_TRANSLATES":
      return {
        ...state,
        translations:null
      }

    default:
      return state;
  }
};

export default translationsReducer;
