// **  Initial State
const initialState = {};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TICKETS_INFO":
      return {
        ...state,
        ticketsInfo: action.data,
      };
      case "GET_TICKETS":
        return {
          ...state,
          tickets: action.data,
        };
    case "GET_CHECKLIST":{      
      return {
        ...state,
        checkList:action.data,
        checkListTotal: action.optData.total_count
      }
    }
    case "GET_SUPPORT_TIPS":{
      
      return {
        ...state,
        supportTips: action.data,
        supportTipsTotal: action.optData.total_count
      }
    }

    default:
      return state;
  }
};

export default ticketsReducer;
